export enum UserPermissions {
  ACCESS_CHILDREN = "access_children",
  VIEW_COMPANY_PROJECTS = "view_company_projects",
  VIEW_COMPANY_ENTITIES = "view_company_entities",
  VIEW_COMPANY_CHECKLISTS = "view_company_checklists",
  VIEW_COMPANY_REPORTS = "view_company_reports",
  MANAGE_COMPANY_PROJECTS = "manage_company_projects",
  MANAGE_COMPANY_CHECKLISTS = "manage_company_checklists",
  ESTIMATE_COMPANY_ENTITIES = "estimate_company_entities",
  REESTIMATE_COMPANY_ENTITIES = "reestimate_company_entities",
  CHANGE_COMPANY_ENTITIES_MANAGER = "change_company_entities_manager",
  MANAGE_COMPANY_USERS = "manage_company_users",
  CREATE_COMPANY_ENTITIES = "create_company_entities",
  VIEW_SUBSCRIPTION = "view_subscription",
}
