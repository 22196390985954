import { BaseApi } from "@/services/api/BaseApi";
import {
  GetEntitiesReportRequestDto,
  GetEntitiesReportResponseDto,
} from "@/services/api/reports/types";
import { merge } from "lodash";

export class ReportsApi extends BaseApi {
  async getCardsReport(payload: GetEntitiesReportRequestDto) {
    merge(payload, {
      filter: {
        types: ["CARD"],
      },
    });

    return (await this.client.post(
      payload,
      `/entities/report`
    )) as Promise<GetEntitiesReportResponseDto>;
  }

  async getEntitiesReport(payload: GetEntitiesReportRequestDto) {
    return (await this.client.post(
      payload,
      `/entities/report`
    )) as Promise<GetEntitiesReportResponseDto>;
  }
}
