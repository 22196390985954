import { ICallsStateModel } from "./../../interfaces";

export const state: ICallsStateModel = {
  list: [],
  page: 1,
  perPage: 20,
  total: 0,
  totalDuration: 0,
  dataIsLoading: false,
  filter: {
    search: "",
    managers: [],
    projects: [],
    checklists: [],
    isEstimated: null,
    linkedCalls: [],
    initialCalls: 0,
    started: {
      from: null,
      to: null,
    },
    duration: {
      max: null,
      min: null,
    },
    estimated: {
      from: null,
      to: null,
    },
    sttState: null
  },
  sort: {
    direction: "asc",
    sort: "startedAt",
  },
  selectedGridColumns: [
    "project",
    "manager",
    "startedAt",
    "duration",
    "direction",
    "phone",
    "estimationValue",
    "estimationDate",
    "hasComments",
    "sttState"
  ],
  managers: [],
  projects: [],
  linkedCalls: [],
};
