import { EntitiesApi } from "@/services/api/entities/EntitiesApi";
import { ProjectsApi } from "@/services/api/projects/ProjectsApi";
import { ManagersApi } from "@/services/api/managers/ManagersApi";
import { EstimationsApi } from "@/services/api/estimations/EstimationsApi";
import { ReportsApi } from "@/services/api/reports/ReportsApi";
import { CategoriesApi } from "@/services/api/categories/CategoriesApi";
import { ChecklistsApi } from "@/services/api/checklists/ChecklistsApi";

export default {
  entitiesApi: new EntitiesApi(),
  estimationsApi: new EstimationsApi(),
  projectsApi: new ProjectsApi(),
  managersApi: new ManagersApi(),
  reportsApi: new ReportsApi(),
  checklistsApi: new ChecklistsApi(),
  categoriesApi: new CategoriesApi(),
};
