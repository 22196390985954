import { getHttpClient } from '../wretchClient'
import type {
  CreateCategoryRequestDto,
  CreateCategoryResponseDto,
  GetCategoriesAllResponseDto,
  GetCategoriesListRequestDto,
  GetCategoriesListResponseDto,
  GetCategoryCriterionsResponseDto,
  GetCategoryResponseDto,
  GetCategoryWithCriterionsResponseDto,
  RemoveCategoriesRequestDto,
  UpdateCategoryRequestDto,
  UpdateCategoryResponseDto
} from './types'
import { BaseApi } from "../BaseApi";

export class CategoriesApi extends BaseApi {
  getCategoriesList(payload: GetCategoriesListRequestDto) {
    return getHttpClient().post(
      payload,
      '/criterions/categories/list'
    ) as Promise<GetCategoriesListResponseDto>
  }

  getCategoriesAll() {
    return getHttpClient().get(
      '/criterions/categories/list'
    ) as Promise<GetCategoriesAllResponseDto>
  }

  getCategory(id: number) {
    return getHttpClient().get(`/criterions/categories/${id}`) as Promise<GetCategoryResponseDto>
  }

  getCategoryWithCriterions(id: number) {
    return getHttpClient().get(
      `/criterions/categories/${id}/criterions`
    ) as Promise<GetCategoryWithCriterionsResponseDto>
  }

  getCategoryCriterions(id: number) {
    return getHttpClient().get(
      `/criterions/categories/${id}/criterions-only`
    ) as Promise<GetCategoryCriterionsResponseDto>
  }

  createCategory(payload: CreateCategoryRequestDto) {
    return getHttpClient().post(
      payload,
      '/criterions/categories/create'
    ) as Promise<CreateCategoryResponseDto>
  }

  updateCategory(id: number, payload: UpdateCategoryRequestDto) {
    return getHttpClient().put(
      payload,
      `/criterions/categories/${id}`
    ) as Promise<UpdateCategoryResponseDto>
  }

  removeCategories(payload: RemoveCategoriesRequestDto) {
    return getHttpClient().post(payload, `/criterions/categories/remove`)
  }
}
