import { ActionTree, ActionContext } from "vuex";
import { ISelectedCallStateModel, IRootStateModel } from "../../../interfaces";
import { SelectedCallMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  CallDetailsResponseModel,
  CallCriterionsListResponseModel,
  CallEstimateRequestModel,
  CallEstimatedCriterionsListResponseModel,
  CallCriterionEstimateModel,
  BaseEntityModelsListResponse,
  SetCallManagerRequestModel,
  CallModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import { StoreActionTypes } from "./../../../types";
import { defaultState } from "../state";
import apiClient from "@/services/api/apiClient";
import { SetEntityEstimationRequestDto } from "@/services/api/entities/types";
import { getStore } from "@/store/getStore";

type AugmentedActionSelectedCallContext = {
  commit<K extends keyof SelectedCallMutations>(
    key: K,
    payload: Parameters<SelectedCallMutations[K]>[1]
  ): ReturnType<SelectedCallMutations[K]>;
} & Omit<ActionContext<ISelectedCallStateModel, IRootStateModel>, "commit">;

export interface CallsActions {
  [ActionTypes.SET_SELECTED_CALL](
    context: AugmentedActionSelectedCallContext,
    id: number | null
  ): Promise<void>;

  [ActionTypes.SET_SELECTED_CALL_CLEAR](
    context: AugmentedActionSelectedCallContext | null
  ): void;

  [ActionTypes.SET_SELECTED_CALL_CATEGORIES](
    context: AugmentedActionSelectedCallContext,
    id: number
  ): Promise<void>;

  [ActionTypes.SET_SELECTED_CALL_SET_ESTIMATION](
    context: AugmentedActionSelectedCallContext
  ): Promise<void>;

  [ActionTypes.SET_SELECTED_CALL_EDIT_ESTIMATION](
    context: AugmentedActionSelectedCallContext
  ): Promise<void>;

  [ActionTypes.SET_SELECTED_CALL_GET_ESTIMATION](
    context: AugmentedActionSelectedCallContext,
    id: number
  ): Promise<void>;

  [ActionTypes.SET_SELECTED_CALL_SET_CURRENT_ESTIMATION](
    context: AugmentedActionSelectedCallContext,
    model: CallCriterionEstimateModel
  ): void;

  [ActionTypes.SET_SELECTED_CALL_SET_MANAGER](
    context: AugmentedActionSelectedCallContext,
    model: SetCallManagerRequestModel
  ): Promise<void>;
}

export const actions: ActionTree<ISelectedCallStateModel, IRootStateModel> &
  CallsActions = {
  async [ActionTypes.SET_SELECTED_CALL](
    { commit, dispatch, getters },
    id: number
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, true);

    try {
      const response: CallDetailsResponseModel =
        await apiClient.entitiesApi.getEntity(id);

      commit(MutationTypes.SET_SELECTED_CALL, response);

      if (response.project && response.project.id) {
        const availableManagersList: BaseEntityModelsListResponse =
          await client.getProjectAvailableManagersList(response.project.id);
        commit(
          MutationTypes.SET_SELECTED_CALL_AVAILABLE_MANAGERS_LIST,
          availableManagersList.items
        );
      }

      dispatch(StoreActionTypes.SET_PLAYER_URL, response.link);
      dispatch(StoreActionTypes.SET_COMMENTS_LIST);

      const currentUserId = getters.getAuthentificationProfile.id;
      const activeEstimation = response.estimations.find(
        (item) => item.auditor.id == currentUserId
      );

      if (activeEstimation) {
        commit(MutationTypes.SET_SELECTED_CALL_RATED, true);
        await dispatch(
          ActionTypes.SET_SELECTED_CALL_GET_ESTIMATION,
          activeEstimation.id
        );

        const selectedCallEstimatedChecklistId = getStore().getters.getSelectedCallEstimatedCheckList?.checklistId;
        const checkListId = selectedCallEstimatedChecklistId  ? selectedCallEstimatedChecklistId : response.checklists.find((item) => item.default).id;

        await commit(
          MutationTypes.SET_SELECTED_CALL_CHECK_LIST_ID,
          checkListId
        );
      } else {
        commit(MutationTypes.SET_SELECTED_CALL_ESTIMATED_CHECK_LIST, null);
        const checkListId = response.checklists.find((item) => item.default).id;
        await dispatch(ActionTypes.SET_SELECTED_CALL_CATEGORIES, checkListId);
      }
    } catch (error) {
      console.error("getCallDetails", error);
    } finally {
      commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_SELECTED_CALL_CLEAR]({ commit, dispatch }) {
    commit(MutationTypes.SET_SELECTED_CALL_CLEAR, defaultState);
    dispatch(StoreActionTypes.SET_PLAYER_CLEAR_PLAYER);
    dispatch(StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT, false);
  },
  async [ActionTypes.SET_SELECTED_CALL_CATEGORIES]({ commit }, id: number) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, true);
    commit(MutationTypes.SET_SELECTED_CALL_ESTIMATED_CHECK_LIST, null);
    commit(MutationTypes.SET_SELECTED_CALL_CHECK_LIST_ID, id);
    commit(MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR, []);

    try {
      const response: CallCriterionsListResponseModel =
        await client.getCallCriterionsList(id);
      commit(MutationTypes.SET_SELECTED_CALL_CATEGORIES, response.categories);
    } catch (error) {
      console.error("getCallCriterionsList", error);
    } finally {
      commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_SELECTED_CALL_SET_ESTIMATION]({
    commit,
    getters,
    dispatch,
  }) {
    commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, true);

    try {
      const callId = getters.getSelectedCall.id;

      const model: SetEntityEstimationRequestDto = {
        checklistId: getters.getSelectedCallCheckListId as number,
        criterions: getters.getSelectedCallCurrentEstimations,
      };
      await apiClient.entitiesApi.setEntityEstimation(callId, model);

      commit(MutationTypes.SET_SELECTED_CALL_RATED, true);
      commit(MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR, []);

      await dispatch(ActionTypes.SET_SELECTED_CALL, callId);
    } catch (error) {
      console.error("setCallEstimation", error);
    } finally {
      commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_SELECTED_CALL_EDIT_ESTIMATION](
    { commit, getters, dispatch },
    auditorId?: number
  ) {
    commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, true);

    try {
      const callId = getters.getSelectedCall.id;

      const model: SetEntityEstimationRequestDto = {
        checklistId: getters.getSelectedCallCheckListId as number,
        criterions: getters.getSelectedCallCurrentEstimations,
        auditorId: auditorId ?? null,
      };

      await apiClient.entitiesApi.setEntityEstimation(callId, model);

      commit(MutationTypes.SET_SELECTED_CALL_RATED, true);
      commit(MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR, []);

      await dispatch(ActionTypes.SET_SELECTED_CALL, callId);
    } catch (error) {
      console.error("setCallEstimation", error);
    } finally {
      commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_SELECTED_CALL_GET_ESTIMATION]({ commit }, id: number) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, true);

    try {
      const response: CallEstimatedCriterionsListResponseModel =
        await client.getCallEstimation(id);

      commit(MutationTypes.SET_SELECTED_CALL_CATEGORIES, response.categories);

      commit(MutationTypes.SET_SELECTED_CALL_ESTIMATED_CHECK_LIST, {
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
        id: response.id,
        checklistId: response.checklistId
      });
    } catch (error) {
      console.error("getCallEstimation", error);
    } finally {
      commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_SELECTED_CALL_SET_CURRENT_ESTIMATION](
    { commit, getters },
    model: any
  ) {
    const categories = getters.getSelectedCallCategories;

    commit(MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION, {
      categories,
      model,
    });
  },
  async [ActionTypes.SET_SELECTED_CALL_SET_MANAGER](
    { commit, getters, dispatch },
    model: SetCallManagerRequestModel
  ) {
    commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, true);

    try {
      const callId: number = (getters.getSelectedCall as CallModel).id;
      await apiClient.entitiesApi.setEntityManager(callId, model);
      await dispatch(ActionTypes.SET_SELECTED_CALL, callId);
    } catch (error) {
      console.error("setCallManager", error);
    } finally {
      commit(MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING, false);
    }
  },
};
