import type { PositionModel } from '@/services/api/models/criterions'

type HasPosition = {
  position?: PositionModel
}

export function makeSortByPositionFn<T extends HasPosition>(
  positionKey: number
): (a: T, b: T) => number {
  return (a, b) => {
    return (a.position?.[positionKey] ?? Infinity) - (b.position?.[positionKey] ?? Infinity)
  }
}
