import { getHttpClient } from '../wretchClient'
import type {
  CreateChecklistRequestDto,
  CreateChecklistResponseDto,
  GetChecklistCriterionsResponseDto,
  GetChecklistResponseDto,
  GetChecklistsExpandedResponseDto,
  GetChecklistsListRequestDto,
  GetChecklistsListResponseDto,
  GetChecklistWithCategoriesResponseDto,
  GetChecklistWithCriterionsResponseDto,
  RemoveChecklistsRequestDto,
  UpdateChecklistCriterionsRequestDto,
  UpdateChecklistNameRequestDto
} from './types'
import { BaseApi } from "../BaseApi";

export class ChecklistsApi extends BaseApi {
  getChecklistsList(payload: GetChecklistsListRequestDto) {
    return getHttpClient().post(
      payload,
      '/checklists/list'
    ) as Promise<GetChecklistsListResponseDto>
  }

  getChecklistsExpanded() {
    return getHttpClient().get(`/checklists/expanded`) as Promise<GetChecklistsExpandedResponseDto>
  }

  getChecklist(id: number) {
    return getHttpClient().get(`/checklists/${id}`) as Promise<GetChecklistResponseDto>
  }

  getChecklistWithCriterions(id: number) {
    return getHttpClient().get(
      `/checklists/${id}/criterions`
    ) as Promise<GetChecklistWithCriterionsResponseDto>
  }

  getChecklistCriterions(id: number) {
    return getHttpClient().get(
      `/checklists/${id}/criterions-only`
    ) as Promise<GetChecklistCriterionsResponseDto>
  }

  getChecklistCategories(id: number) {
    return getHttpClient().get(
      `/checklists/${id}/criterions-expanded`
    ) as Promise<GetChecklistWithCategoriesResponseDto>
  }

  createChecklist(payload: CreateChecklistRequestDto) {
    return getHttpClient().post(
      payload,
      '/checklists/create'
    ) as Promise<CreateChecklistResponseDto>
  }

  updateChecklistName(id: number, payload: UpdateChecklistNameRequestDto) {
    return getHttpClient().put(payload, `/checklists/${id}`) as Promise<CreateChecklistResponseDto>
  }

  updateChecklistCriterions(id: number, payload: UpdateChecklistCriterionsRequestDto) {
    return getHttpClient().post(
      payload,
      `/checklists/${id}/update-criterions`
    ) as Promise<CreateChecklistResponseDto>
  }

  removeChecklists(payload: RemoveChecklistsRequestDto) {
    return getHttpClient().post(payload, '/checklists/remove')
  }
}
